<template>
  <div>
    <cabecalho-pagina
      :titulo="tituloPagina"
      :total-registros="tabela.quantidadeItens"
      sem-botoes
    />
    <botao-padrao
      v-if="podeExcluir"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2 tabela-revisao"
      :colunas="tabela.colunas"
      :pagina-atual="tabela.paginaAtual"
      :por-pagina="tabela.porPagina"
      mostrar-seletor-check-box
      :mostrar-seletor="false"
      sem-paginacao
      paginacao-em-memoria
      sort-field="revisao"
      :sort-order="-1"
      :mostra-header="false"
    >
      <template v-slot:seletor="{ slotProps }">
        <div class="d-flex justify-center">
          <v-simple-checkbox
            v-if="slotProps.data?.revisao == (tabela.dados.length - 1) && !slotProps.data?.dataPublicacao"
            :value="slotProps.data.isSelected"
            @input="selecionarItem(slotProps.data)"
          />
        </div>
      </template>
    
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item @click="abrirAcao(slotProps.data)">
            {{ acaoLabel(slotProps.data) }}
          </dropdown-padrao-item>
          <dropdown-padrao-item @click="abrirStimusSoft(slotProps.data)">
            {{
              $t(
                'modulos.procedimentos_eletronicos.botoes.desenhar_certificado'
              )
            }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>

      <template v-slot:justificativa="{ slotProps }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="text-overflow"
              v-on="on"
            >
              {{ slotProps.data.justificativa }}
            </div>
          </template>
          <span>{{ slotProps.data.justificativa }}</span>
        </v-tooltip>
      </template>
    </tabela-padrao-prime-vue>
    <div class="d-flex justify-end mt-2">
      <botao-padrao
        outlined
        color="secondary"
        @click="voltarPagina"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
    <modal-padrao
      ref="modal"
      :titulo="$t('modulos.procedimentos_eletronicos.titulos.certificado')"
      max-width="80%"
      :mostra-botao-salvar="false"
    >
      <stimulsoft-visualizador ref="visualizador" />
    </modal-padrao>
  </div>
</template>

<script>
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import ProcedimentoEletronicoService from '@/common/services/cadastros/ProcedimentoEletronicoService';
import helpers from '@/common/utils/helpers';
import { StimulsoftVisualizador } from '@/components/misc';
import _ from 'lodash';

export default {
  components: {
    CabecalhoPagina,
    StimulsoftVisualizador,
  },
  props: {
    id: { type: String, default: null }
  },
  data() {
    return {
      tituloPagina: '',
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'revisao',
            text: this.$t('modulos.procedimentos_eletronicos.tabela.revisao'),
            sortable: true,
          },
          {
            value: 'dataCriacao',
            text: this.$t('modulos.procedimentos_eletronicos.tabela.criado_em'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true);
            },
          },
          {
            value: 'usuarioCriacao.nome',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela.usuario_criacao'
            ),
            sortable: true,
          },
          {
            value: 'dataPublicacao',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela.publicada_em'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true);
            },
          },
          {
            value: 'usuarioPublicacao.nome',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela.usuario_publicacao'
            ),
            sortable: true,
          },
          {
            value: 'justificativa',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela.justificativa'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    podeExcluir() {
      if (this.tabela.selecionados.length != 1) return false;

      return !this.tabela.selecionados[0].dataPublicacao;
    },
  },
  mounted() {
    this.buscarProcedimento();
    this.listarRegistros();
  },
  methods: {
    selecionarItem: function (item) {
      if (!item.isSelected) {
        item.isSelected = true;
        this.tabela.selecionados.push(item);
        console.log(item, "Add")
      } else {
        console.log(item, "Remove")
        item.isSelected = false;
        this.tabela.selecionados = this.tabela.selecionados.filter(
          ({ id }) => id !== item.id
        );
      }
      // ++this.keyTable;
    },
    acaoLabel: function (item) {
      return item.dataPublicacao === null
        ? this.$t('geral.botoes.editar')
        : this.$t('geral.botoes.visualizar');
    },
    abrirAcao: function (item) {
      if (item.dataPublicacao === null) {
        this.abrirEditar(item);
      } else {
        this.abrirVisualizar(item);
      }
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    buscarProcedimento() {
      ProcedimentoEletronicoService.buscar(this.id)
        .then((res) => {
          this.tituloPagina = this.$t(
            'modulos.procedimentos_eletronicos.titulos.visualizar_revisao',
            { procedimento_eletronico: res.data.codigo }
          );
          this.$store.dispatch('Layout/alterarTituloPagina', this.tituloPagina);
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.procedimentos_eletronicos.erros.id_invalido')
          );
        });
    },
    listarRegistros() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ProcedimentoEletronicoService.listarRevisoes(this.id)
        .then((res) => {
          res.data.forEach(item => item.isSelected = false);
          this.tabela.dados = _.cloneDeep(res.data);
          this.tabela.quantidadeItens = this.tabela.dados.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        ProcedimentoEletronicoService.excluirRevisao(
          this.tabela.selecionados[0].id
        )
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(
                `modulos.procedimentos_eletronicos.exclusao_revisao_sucesso`
              )
            );
          })
          .catch(() => {
            this.toastErro(this.$t(`geral.erros.erroGenerico`));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    voltarPagina() {
      this.$router.push({ name: 'procedimentos-eletronicos' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'procedimento-eletronico-revisao-nao-publicada',
        params: { id: item.id },
      });
    },
    abrirVisualizar(item) {
      this.$router.push({
        name: 'procedimento-eletronico-revisao',
        params: { id: item.id },
      });
    },
    // abrirStimusSoft(item) {
    //   this.$store.dispatch('Layout/iniciarCarregamento');
    //   ProcedimentoEletronicoService.buscarCertificadoPorRevisao(item.id)
    //     .then((res) => {
    //       this.$refs.modal.abrirModal();
    //       setTimeout(() => {
    //         this.$refs.visualizador.montarComponente(res.data.certificado);
    //       }, 500);
    //     })
    //     .finally(() => {
    //       this.$store.dispatch('Layout/terminarCarregamento');
    //     });
    // },
    abrirStimusSoft(item) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ProcedimentoEletronicoService.buscarCertificadoPorRevisao(item.id)
        .then(() => {
          window.open(
        this.$router.resolve({
          name: 'procedimento-eletronico-certificado-revisao',
          params: { id: item.id },
        }).href,
        '_blank'
      );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

<style lang="scss">
.tabela-revisao {
  .text-overflow {
    max-width: 300px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
